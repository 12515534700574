<template>
    <div>
        <HeaderBar/>

        <main class="main">
            <div class="row col-12">
                <LocationDetailMenu activeCategory="Workstation" activeItem="Update"/>

                <div v-if="isLoading">
                    <p class="mt-4">
                        Loading workstation...
                    </p>
                </div>

                <template v-else-if="invalidWorkstationId">
                    <p class="mt-4">
                        <InputFieldError errors="Workstation not found."/>
                    </p>
                </template>

                <template v-else>
                    <div class="col-10">
                        <div class="row">
                            <div class="col-md-6 my-3">
                                <label for="name">
                                    Name
                                </label>

                                <input
                                    id="name"
                                    type="text"
                                    class="form-control"
                                    v-model="workstation.NAME"
                                    :disabled="isLoading || isSaving"
                                >

                                <InputFieldError attribute="NAME" :errors="errors"/>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6 my-3">
                                <label for="computer">
                                    Computer
                                </label>

                                <v-select
                                    id="computer"
                                    :options="computerOptions"
                                    label="name"
                                    v-model="selectedComputer"
                                    :clearable="false"
                                    :disabled="isLoading || isSaving"
                                    @option:selected="clearWorkstationPrinters()"
                                    required
                                />

                                <InputFieldError attribute="PRINTNODE_DESKTOP_ID" :errors="errors"/>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6 my-3">
                                <table class="w-100">
                                    <thead>
                                        <tr>
                                            <th>
                                                Printer type
                                            </th>

                                            <th>
                                                Printer
                                            </th>

                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="(workstationPrinter, index) in workstationPrinters" :key="index">
                                            <td class="w-25">
                                                <v-select
                                                    :options="printerTypeOptions"
                                                    :reduce="option => option.key"
                                                    label="label"
                                                    v-model="workstationPrinter.printer_type"
                                                    :clearable="false"
                                                    :disabled="isLoading || isSaving"
                                                    required
                                                />
                                            </td>

                                            <td class="w-50">
                                                <v-select
                                                    :options="printerOptions"
                                                    :reduce="option => option.printer_id"
                                                    label="printer_name"
                                                    v-model="workstationPrinter.printer_id"
                                                    :clearable="false"
                                                    :disabled="isLoading || isSaving"
                                                    required
                                                >
                                                    <template #option="option">
                                                        <div :class="option.computer_id == selectedComputer?.id ? 'font-weight-bold' : ''">
                                                            {{ option.printer_name }}
                                                        </div>
                                                    </template>
                                                </v-select>
                                            </td>

                                            <td class="w-25">
                                                <button
                                                    class="btn btn-danger sm"
                                                    @click.prevent="removePrinter(index)"
                                                    :disabled="isLoading || isSaving"
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 mt-3 mb-5">
                                <button
                                    type="button"
                                    v-if="!isLoading"
                                    :disabled="isLoading || isSaving"
                                    @click="addPrinter()"
                                    class="btn md btn-primary"
                                >
                                    Add printer
                                </button>
                            </div>
                        </div>

                        <InputFieldError attribute="general" :errors="errors"/>

                        <div class="row">
                            <div class="col-12">
                                <button
                                    type="button"
                                    v-if="!isLoading"
                                    :disabled="isLoading || isSaving"
                                    @click="save()"
                                    class="btn md btn-primary"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </main>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import LocationDetailMenu from '@/components/_LocationDetailMenu.vue';
import InputFieldError from '@/components/_InputFieldError.vue';
import vSelect from 'vue-select';

export default {
    name: 'WorkstationUpdate',
    components: {
        'HeaderBar': HeaderBar,
        'LocationDetailMenu': LocationDetailMenu,
        'InputFieldError': InputFieldError,
        'v-select': vSelect,
    },
    data() {
        return {
            errors: {},
            isSaving: false,
            isLoadingComputers: false,
            isLoadingPrinterTypes: false,
            isLoadingPrinterOptions: false,
            isLoadingWorkstation: false,
            invalidWorkstationId: false,
            workstation: {
                NAME: '',
                PRINTNODE_DESKTOP_ID: '',
                PRINTNODE_DESKTOP_NAME: ''
            },
            workstationPrinters: [],
            computerOptions: [],
            printerOptions: [],
            existingPrinterOptionsToDelete: [],
            printerTypeOptions: [],
            selectedComputer: null,
        };
    },
    created() {
        this.getPrinterTypeOptions();
        this.getPrintNodeComputers();
        this.getPrintNodePrinters();

        if (this.workstationId) {
            this.getWorkstation();
        }
    },
    methods: {
        getWorkstation() {
            this.isLoadingWorkstation = true;

            const url = new URL(this.$store.state.baseUrl + 'workstation/get-by-id');
            url.searchParams.set('workstationId', this.workstationId);
            url.searchParams.set('locationId', this.$route.params.locationId);

            this.$http.get(url.href).then((data) => {
                this.isLoadingWorkstation = false;

                if (data?.data?.code === 200) {
                    this.workstation = data?.data?.data || null;

                    this.selectedComputer = {
                        id: this.workstation.PRINTNODE_DESKTOP_ID,
                        name: this.workstation.PRINTNODE_DESKTOP_NAME
                    };

                    if (this.workstation?.workstationSettings) {
                        this.populateWorkstationPrinters(this.workstation.workstationSettings);
                    }
                } else {
                    this.invalidWorkstationId = true;
                }
            });
        },
        getPrintNodeComputers() {
            this.isLoadingComputers = true;

            const url = new URL(this.$store.state.baseUrl + 'print-node/view-computers');
            url.searchParams.set('locationId', this.$route.params.locationId);

            this.$http.get(url.href).then((data) => {
                this.isLoadingComputers = false;

                if (data?.data?.code === 200) {
                    this.computerOptions = data?.data.data ? Object.values(data.data.data) : [];
                } else if (data?.data?.errors) {
                    this.errors = data.data.errors;
                } else {
                    this.errors = {general: 'Something went wrong while getting printnode computers.'};
                }
            });
        },
        getPrinterTypeOptions() {
            this.isLoadingPrinterTypes = true;

            const url = new URL(this.$store.state.baseUrl + 'workstation-settings/get-printer-options');
            url.searchParams.set('locationId', this.$route.params.locationId);

            this.$http.get(url.href).then((data) => {
                this.isLoadingPrinterTypes = false;

                if (data?.data?.code === 200) {
                    this.printerTypeOptions = data?.data?.data || [];
                } else if (data?.data?.errors) {
                    this.errors = data.data.errors;
                    this.printerTypeOptions = [];
                } else {
                    this.errors = {general: 'Something went wrong while getting printer type options.'};
                }
            });
        },
        getPrintNodePrinters() {
            this.isLoadingPrinterOptions = true;
            this.printerOptions = [];

            const url = new URL(this.$store.state.baseUrl + 'print-node/get-configured-printers');
            url.searchParams.set('locationId', this.$route.params.locationId);

            this.$http.get(url.href).then((data) => {
                this.isLoadingPrinterOptions = false;

                if (data?.data?.code === 200) {
                    this.printerOptions = data?.data.data || [];
                } else if (data?.data?.errors) {
                    this.errors = data.data.errors;
                    this.printerOptions = [];
                }
            });
        },
        save() {
            this.errors = {};
            this.isSaving = true;

            const url = new URL(this.$store.state.baseUrl + 'workstation/save');

            if (this.workstationId) {
                url.searchParams.set('workstationId', this.workstationId);
            }

            const params = new URLSearchParams();
            params.append('Workstation[LOCATION_ID]', this.$route.params.locationId);
            params.append('Workstation[NAME]', this.workstation.NAME);
            params.append('Workstation[PRINTNODE_DESKTOP_ID]', this.selectedComputer.id);
            params.append('Workstation[PRINTNODE_DESKTOP_NAME]', this.selectedComputer.name);
            params.append('WorkstationSettings[PRINTERS]', JSON.stringify(this.workstationPrinters));
            params.append('WorkstationSettings[PRINTERS_TO_DELETE]', this.existingPrinterOptionsToDelete);

            this.$http.post(url.href, params).then((data) => {
                this.isSaving = false;

                if (data?.data?.code === 200) {
                    this.$store.commit('showSuccessMessage', 'The workstation has been saved.');

                    if (!this.workstationId) {
                        this.$router.push({
                            name: 'WorkstationUpdate',
                            params: {
                                locationId: this.$route.params.locationId,
                                workstationId: data?.data?.data?.ID
                            }
                        });
                    } else {
                        this.getWorkstation();
                    }
                } else if (data?.data?.errors) {
                    this.errors = data.data.errors;
                } else {
                    this.errors = {general: 'Something went wrong while trying to save the workstation.'};
                }
            });
        },
        addPrinter() {
            this.workstationPrinters.push({
                id: '',
                printer_type: '',
                printer_id: ''
            });
        },
        removePrinter(index) {
            const workstationPrinterId = this.workstationPrinters[index]?.id || null;

            if (workstationPrinterId) {
                this.existingPrinterOptionsToDelete.push(workstationPrinterId);
            }

            this.workstationPrinters.splice(index, 1);
        },
        populateWorkstationPrinters(workstationSettings) {
            this.workstationPrinters = [];

            workstationSettings.forEach((setting) => {
                this.workstationPrinters.push({
                    id: setting.ID,
                    printer_type: setting.KEY,
                    printer_id: parseInt(setting.VALUE)
                });
            });
        },
        clearWorkstationPrinters() {
            for (let i = 0; i < this.workstationPrinters.length; i++) {
                const workstationPrinterId = this.workstationPrinters[i]?.id || null;

                if (workstationPrinterId) {
                    this.existingPrinterOptionsToDelete.push(workstationPrinterId);
                }
            }

            this.workstationPrinters = [];
        }
    },
    computed: {
        workstationId() {
            return this.$route.params.workstationId;
        },
        isLoading() {
            return this.isLoadingWorkstation || this.isLoadingComputers || this.isLoadingPrinterOptions || this.isLoadingPrinterTypes;
        }
    },
    watch: {
        workstationId(value) {
            this.workstation.NAME = null;

            if (value) {
                this.getWorkstation();
            }
        }
    }
}
</script>

<style scoped>

</style>
