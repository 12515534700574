<template>
    <div>
        <HeaderBar/>

        <main class="main">
            <section class="section-dashboard border-top">
                <div class="container">
                    <h2 class="section-title">
                        Shipping
                    </h2>

                    <template v-if="isLoadingModules || isLoadingModuleAccess">
                        Loading modules...
                    </template>

                    <template v-else-if="!hasModulesAvailable">
                        No modules available.
                    </template>

                    <template v-else>
                        <div class="card-list sm">
                            <MenuCardItem
                                :route="{name: 'ShippingSortingAndInboundDashboard'}"
                                :icon-path="require('@/assets/images/colored-icons/receive.svg')"
                                label="SORTING AND INBOUND"
                                v-if="hasSortingAndInboundAccess"
                            />

                            <MenuCardItem
                                :route="{name: 'ShippingManifestsAndContainersDashboard'}"
                                :icon-path="require('@/assets/images/colored-icons/inventory.svg')"
                                label="MANIFESTS AND CONTAINERS"
                                v-if="hasManifestsAndContainersAccess"
                            />

                            <MenuCardItem
                                :route="{name: 'ShippingOutboundDashboard'}"
                                :icon-path="require('@/assets/images/colored-icons/inbound.svg')"
                                label="OUTBOUND"
                                v-if="hasOutboundAccess"
                            />
                        </div>

                        <div class="card-list sm">
                            <MenuCardItem
                                :route="{name: 'GenerateBarcodes'}"
                                :icon-path="require('@/assets/images/colored-icons/receive.svg')"
                                label="GENERATE BARCODES"
                                v-if="canGenerateBarcodes"
                            />
                        </div>
                    </template>
                </div>
            </section>

            <div class="scrollable-area">
                <div class="section-footer">
                    <div class="container">
                        <div class="d-flex justify-content-between">
                            <router-link :to="{name: 'ModuleSelect'}" class="btn btn-secondary">
                                Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import MenuCardItem from '@/components/_MenuCardItem.vue';

export default {
    name: 'ShippingModuleSelect',
    components: {
        'HeaderBar': HeaderBar,
        'MenuCardItem': MenuCardItem,
    },
    data() {
        return {
            isLoadingModules: false,
            isLoadingModuleAccess: false,
            hasAccessToModules: false,
            canReceiveToManifest: false,
            canReceiveToMasterBox: false,
            canViewManifests: false,
            canHandleScoutContainers: false,
            canShipManifests: false,
            canShipShipments: false,
            canShipContainerGroups: false,
            canGenerateBarcodes: false,
        };
    },
    mounted() {
        this.$root.$on('loadedRoleModuleAccess', () => {
            this.isLoadingModuleAccess = false;
            this.moduleAccess = this.$store.state.roleAccess?.['role-module-access']?.[this.$store.state.users.currentUser.ROLE_ID];

            const shippingModuleAccess = this.moduleAccess?.['shipping']?.['shipping'] ?? undefined;

            this.canReceiveToManifest = shippingModuleAccess?.['receive-to-manifest']?.ACCESS;
            this.canReceiveToMasterBox = shippingModuleAccess?.['receive-to-master-box']?.ACCESS;
            this.canViewManifests = shippingModuleAccess?.['view-manifests']?.ACCESS;
            this.canHandleScoutContainers = shippingModuleAccess?.['handle-scout-containers']?.ACCESS;
            this.canShipManifests = shippingModuleAccess?.['ship-manifests']?.ACCESS;
            this.canShipShipments = shippingModuleAccess?.['ship-shipments']?.ACCESS;
            this.canShipContainerGroups = shippingModuleAccess?.['ship-scout-container-groups']?.ACCESS;
            this.canGenerateBarcodes = shippingModuleAccess?.['generate-barcodes']?.ACCESS;
        });
    },
    created() {
        this.isLoadingModules = true;
        this.isLoadingModuleAccess = true;

        this.$store.dispatch('userSettings/getSelectedLocation', module).then((data) => {
            this.currentLocation = data;

            this.isLoadingModules = false;
            this.hasAccessToModules = this.currentLocation != null;
        });
    },
    computed: {
        hasModulesAvailable() {
            return this.hasAccessToModules && (
                this.canReceiveToManifest ||
                this.canReceiveToMasterBox ||
                this.canViewManifests ||
                this.canHandleScoutContainers ||
                this.canShipManifests ||
                this.canShipShipments
            );
        },
        hasSortingAndInboundAccess() {
            return this.canReceiveToManifest || this.canReceiveToMasterBox;
        },
        hasManifestsAndContainersAccess() {
            return this.canViewManifests || this.canHandleScoutContainers;
        },
        hasOutboundAccess() {
            return this.canShipManifests || this.canShipShipments || this.canShipContainerGroups;
        }
    }
}
</script>

<style scoped>
.card-item--link.inactive {
    filter: grayscale(60%);
    opacity: 0.3;
    pointer-events: none;
}

.card-item--link {
    color: #1d2640;
}

.card-item--link:hover {
    text-decoration: none;
    color: #4a90e2;
}
</style>
