<template>
    <div>
        <HeaderBar/>

        <main class="main">
            <div class="row col-12">
                <LocationDetailMenu activeCategory="Workstation" activeItem="General"/>

                <div v-if="isLoading">
                    <div class="mt-4">
                        Loading general information...
                    </div>
                </div>

                <template v-else>
                    <div class="col-10">
                        <div class="row">
                            <div class="col-md-6 my-3">
                                <label for="quantity-requested">
                                    API key
                                </label>

                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="apiKey"
                                    :disabled="isLoading || isSaving"
                                >
                            </div>
                        </div>

                        <InputFieldError :errors="errors"/>

                        <button
                            type="button"
                            v-if="!isLoading"
                            :disabled="isSaving"
                            @click="save()"
                            class="btn md btn-primary"
                        >
                            Save
                        </button>
                    </div>
                </template>
            </div>
        </main>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import LocationDetailMenu from '@/components/_LocationDetailMenu.vue';
import InputFieldError from '@/components/_InputFieldError.vue';

export default {
    name: 'WorkstationGeneral',
    components: {
        'HeaderBar': HeaderBar,
        'LocationDetailMenu': LocationDetailMenu,
        'InputFieldError': InputFieldError,
    },
    data() {
        return {
            errors: {},
            isSaving: false,
            isLoading: false,
            apiKey: null,
        };
    },
    created() {
        this.getApiKey();
    },
    methods: {
        getApiKey() {
            const url = new URL(this.$store.state.baseUrl + 'workstation/get-api-key');
            url.searchParams.set('locationId', this.$route.params.locationId);

            this.$http.get(url.href).then((data) => {
                if (data?.data?.code === 200) {
                    this.apiKey = data?.data?.data || null;
                } else if (data?.data?.errors) {
                    this.errors = data.data.errors;
                } else {
                    this.errors = 'Something went wrong while trying to retrieve the API key.';
                }
            });
        },
        save() {
            this.errors = {};
            this.isSaving = true;

            const url = new URL(this.$store.state.baseUrl + 'workstation/save-api-key');
            const params = new URLSearchParams();
            params.append('API_KEY', this.apiKey || '');
            params.append('LOCATION_ID', this.$route.params.locationId);

            this.$http.post(url.href, params).then((data) => {
                this.isSaving = false;

                if (data?.data?.code === 200) {
                    this.$store.commit('showSuccessMessage', 'The API key has been saved.');
                    this.getApiKey();
                } else if (data?.data?.errors) {
                    this.errors = data.data.errors;
                } else {
                    this.errors = 'Something went wrong while trying to save the API key.';
                }
            });
        },
    }
}
</script>

<style scoped>

</style>
