<template>
    <LeftMenuBase :menu="menu"/>
</template>


<script>
import LeftMenuBase from './_LeftMenuBase';

export default {
    components: {
        'LeftMenuBase': LeftMenuBase
    },
    props: ['activeCategory', 'activeItem', 'roleId'],
    computed: {
        locationId() {
            if (this.$route.params.locationId) {
                return this.$route.params.locationId;
            }

            return this.$route.query.locationId;
        },
        menu() {
            let menu = {
                activeCategory: this.activeCategory,
                activeItem: this.activeItem,
                categories: {
                    Location: {
                        name: 'Locations',
                        routeName: 'LocationOverview',
                        items: {
                            Overview: {routeName: 'LocationOverview'},
                            Create: {
                                name: this.locationId ? 'View' : 'Create',
                                routeName: 'LocationUpdate',
                                routeQuery: {locationId: this.locationId}
                            },
                        }
                    },

                }
            };

            if (this.locationId) {
                menu['categories']['Workstation'] = {
                    name: 'Workstations',
                    routeName: 'WorkstationGeneral',
                    routeParams: {locationId: this.locationId},
                    items: {
                        General: {
                            routeName: 'WorkstationGeneral',
                            routeParams: {locationId: this.locationId}
                        },
                        Overview: {
                            routeName: 'WorkstationOverview',
                            routeParams: {locationId: this.locationId}
                        },
                        Update: {
                            name: this.$route.params.workstationId ? 'Update' : 'Create',
                            routeName: 'WorkstationUpdate',
                            routeParams: {
                                locationId: this.locationId,
                                workstationId: this.$route.params.workstationId
                            }
                        },
                    }
                };
            }

            return menu;
        }
    }
}
</script>
