<template>
    <div>
        <HeaderBar/>

        <main class="main">
            <div class="row col-12">
                <SettingsMenu activeCategory="PackageType" activeItem="Overview"/>

                <div v-if="isLoading">
                    <div class="mt-4">
                        Loading package types...
                    </div>
                </div>

                <div v-else-if="!packageTypes?.length">
                    <div class="mt-4">
                        No package types found.
                    </div>
                </div>

                <template v-else>
                    <div class="col-10">
                        <div class="table-responsive mt-4">
                            <table class="w-100">
                                <thead>
                                    <tr>
                                        <th>
                                            Label
                                        </th>

                                        <th>
                                            Default metric length
                                        </th>

                                        <th>
                                            Default metric width
                                        </th>

                                        <th>
                                            Default metric height
                                        </th>

                                        <th>
                                            Default metric weight
                                        </th>

                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="(packageType, key) in packageTypes" :key="key">
                                        <td>
                                            {{ packageType.LABEL }}
                                        </td>

                                        <td>
                                            {{ packageType.DEFAULT_METRIC_LENGTH }} cm
                                        </td>

                                        <td>
                                            {{ packageType.DEFAULT_METRIC_WIDTH }} cm
                                        </td>

                                        <td>
                                            {{ packageType.DEFAULT_METRIC_HEIGHT }} cm
                                        </td>

                                        <td>
                                            {{ packageType.DEFAULT_METRIC_WEIGHT }} kg
                                        </td>

                                        <td>
                                            <router-link :to="{name: 'PackageTypeUpdate', query: {id: packageType.ID}}">
                                                Update
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </template>
            </div>
        </main>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import SettingsMenu from '@/components/_SettingsMainMenu.vue';

export default {
    name: 'PackageTypeOverview',
    components: {
        'HeaderBar': HeaderBar,
        'SettingsMenu': SettingsMenu
    },
    data() {
        return {
            isLoading: false,
            packageTypes: [],
        };
    },
    created() {
        this.getPackageTypes();
    },
    methods: {
        getPackageTypes() {
            this.isLoading = true;
            let url = new URL(this.$store.state.baseUrl + 'package-types/get-all');

            this.$http.get(url.href).then((data) => {
                this.isLoading = false;

                if (data.status === 200) {
                    this.packageTypes = data.data ?? [];
                }
            });
        },
    }
}
</script>

<style scoped>
table tbody tr:hover {
    background: #f6f6f6;
}

table th, table td {
    padding: 5px;
}
</style>
