<template>
    <LeftMenuBase :menu="menu"/>
</template>


<script>
import LeftMenuBase from './_LeftMenuBase';

export default {
    components: {
        'LeftMenuBase': LeftMenuBase
    },
    props: ['activeCategory', 'activeItem'],
    data() {
        return {
            menu: {
                categories: {
                    Role: {
                        name: 'Role',
                        routeName: 'RoleOverview',
                        items: {
                            Overview: {routeName: 'RoleOverview'},
                            Create: {routeName: 'RoleUpdate'}
                        }
                    },
                    User: {
                        name: 'User',
                        routeName: 'UserOverview',
                        items: {
                            Overview: {routeName: 'UserOverview'},
                            Create: {routeName: 'UserUpdate'},
                        }
                    },
                    Location: {
                        name: 'Locations',
                        routeName: 'LocationOverview',
                        items: {
                            Overview: {routeName: 'LocationOverview'},
                            Create: {routeName: 'LocationUpdate'},
                        }
                    },
                    LocationMerchantSettings: {
                        name: 'Location Merchant Settings',
                        routeName: 'LocationMerchantSettingsUpdate',
                        items: {
                            Update: {routeName: 'LocationMerchantSettingsUpdate'},
                        }
                    },
                    PackageType: {
                        name: 'Package types',
                        routeName: 'PackageTypeOverview',
                        items: {
                            Overview: {routeName: 'PackageTypeOverview'},
                            Create: {routeName: 'PackageTypeUpdate'},
                        }
                    },
                    Profile: {
                        name: 'Profile',
                        routeName: 'ProfileUpdate',
                        items: {
                            Update: {routeName: 'ProfileUpdate'},
                        }
                    },
                    Connection: {
                        name: 'Connections',
                        routeName: 'ConnectionOverview',
                        items: {
                            Overview: {routeName: 'ConnectionOverview'},
                        }
                    },
                }
            }
        };
    },
    created() {
        this.menu['activeCategory'] = this.activeCategory;
        this.menu['activeItem'] = this.activeItem;
    }
}
</script>
