<template>
    <div>
        <HeaderBar/>

        <main class="main">
            <div class="scrollable-area">
                <div class="scrollable-content">
                    <div class="container">
                        <form @submit.prevent="scanReference()">
                            <div class="form-group mb-0">
                                <label for="rma-input" class="label-title">
                                    Scan to receive a parcel to a container
                                </label>

                                <input type="text"
                                       placeholder="Scan parcel shipment tracking code"
                                       class="form-control lg"
                                       id="rma-input"
                                       v-model="parcelReference"
                                       ref="scanReferenceInput"
                                       :disabled="isLoading || isSaving"
                                >
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <InputFieldError attribute="parcelReference" :errors="errors"/>
                                </div>
                            </div>
                        </form>

                        <div class="scan-manisfest-container my-3 p-3">
                            <div class="row scout-containers-container">
                                <template v-if="hasScannedValidParcel">
                                    <div class="col-12 mb-4">
                                        <button class="btn btn-primary btn-md"
                                                @click.prevent="openNewContainer()"
                                                :disabled="isLoadingContainers || isCreatingContainer || isSaving"
                                        >
                                            Create new container
                                        </button>

                                        <InputFieldError attribute="createContainer" :errors="errors"/>
                                    </div>

                                    <template v-if="containers?.length">
                                        <div class="col-sm-6 col-lg-3"
                                             v-for="container in containers"
                                             :key="container.ID"
                                        >
                                            <OverviewCardItem
                                                :label="container.NAME"
                                                :count="container.DELIVERY_ITEMS"
                                                :items="[{label: 'Lane', value: container.LANE}]"
                                                class="my-3"
                                            />
                                        </div>
                                    </template>

                                    <template v-else-if="isLoadingContainers">
                                        <div class="col-12">
                                            Loading available containers...
                                        </div>
                                    </template>

                                    <template v-else>
                                        <div class="col-12">
                                            No open containers found.
                                        </div>
                                    </template>
                                </template>

                                <template v-else>
                                    <div class="col-12">
                                        Scan a parcel in order to see the available containers.
                                    </div>
                                </template>
                            </div>
                        </div>

                        <form @submit.prevent="scanContainer()">
                            <div class="form-group mb-0">
                                <input type="text"
                                       placeholder="Scan container code"
                                       class="form-control lg"
                                       :disabled="!scanContainerInputActive || isSaving"
                                       v-model="containerCode"
                                       ref="scanContainerInput"
                                >
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <InputFieldError attribute="containerCode" :errors="errors"/>
                                </div>
                            </div>
                        </form>

                        <p class="saving-message" v-if="isSaving">
                            Adding parcel {{ parcelReference }} to manifest {{ containerCode }}...
                        </p>

                        <template v-if="recentlyAddedItems">
                            <div v-for="(item, key) in recentlyAddedItems"
                                 class="d-flex align-items-center mb-3"
                                 :class="'added-to-manifest-message-' + key"
                                 :key="key"
                            >
                                <p class="mb-0 mr-3">
                                    Parcel {{ item.parcelReference }} has been added to container {{ item.containerCode }}
                                </p>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="section-footer">
                    <div class="container">
                        <div class="d-flex justify-content-between">
                            <router-link :to="{name: 'ShippingSortingAndInboundDashboard'}" class="btn btn-secondary">
                                Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import InputFieldError from '@/components/_InputFieldError.vue';
import OverviewCardItem from '@/components/_OverviewCardItem.vue';

export default {
    components: {
        'HeaderBar': HeaderBar,
        'InputFieldError': InputFieldError,
        'OverviewCardItem': OverviewCardItem,
    },
    data() {
        return {
            parcelReference: null,
            isLoadingParcel: false,
            isLoadingContainers: false,
            isCreatingContainer: false,
            isPrintingContainer: false,
            isSaving: false,
            hasScannedValidParcel: false,
            errors: {},
            manifest: null,
            containerCode: null,
            scanContainerInputActive: false,
            recentlyAddedItems: [],
            containers: [],
            parcelNextTransportStepId: null,
            deliveryItemId: null,
        };
    },
    mounted() {
        /* Focus on the "Scan reference" input field, so the user can immediately enter a shipment reference on page load */
        this.$refs.scanReferenceInput.focus();
    },
    created() {

    },
    methods: {
        getScoutContainers() {
            this.isLoadingContainers = true;

            const url = new URL(this.$store.state.baseUrl + 'scout-container-group/get-containers');
            url.searchParams.set('transportStepId', this.parcelNextTransportStepId);
            url.searchParams.set('state', 'Open');

            this.$http.get(url.href).then((data) => {
                this.isLoadingContainers = false;
                this.containers = data?.data ?? [];

                if (this.containers?.length) {
                    this.scanContainerInputActive = true;

                    this.$nextTick(() => {
                        this.$refs.scanContainerInput?.focus();
                    });
                }
            });
        },
        scanReference() {
            this.isLoadingParcel = true;
            this.errors = {};
            this.clearData();

            /* Check if the reference is empty, or exists of only spaces */
            if (!this.parcelReference || /^\s*$/.test(this.parcelReference)) {
                this.errors = {parcelReference: 'Enter a parcel reference.'};
                this.isLoadingParcel = false;
                return false;
            }

            const url = new URL(this.$store.state.baseUrl + 'shipment-item/request-new');
            url.searchParams.set('barcode', this.parcelReference);
            url.searchParams.set('state', 'Open');

            this.$http.get(url.href).then((data) => {
                this.isLoadingParcel = false;

                /*
                 * Response code 404:
                 * - Show the error message
                 *
                 * Response code 200:
                 * - The parcel can be received to a sorted manifest.
                 */
                if (data.data.code === 404) {
                    this.errors = {parcelReference: data.data.errors};

                    this.$nextTick(() => {
                        this.$refs.scanReferenceInput?.focus();
                    });
                } else {
                    this.hasScannedValidParcel = true;

                    this.parcelNextTransportStepId = data?.data?.data?.NEXT_TRANSPORT_STEP_ID;
                    this.deliveryItemId = data?.data?.data?.DELIVERY_ITEM_ID;
                    this.containers = data?.data?.data?.CONTAINERS ?? [];

                    if (this.containers?.length) {
                        this.scanContainerInputActive = true;

                        this.$nextTick(() => {
                            this.$refs.scanContainerInput?.focus();
                        });
                    }
                }
            });
        },
        scanContainer() {
            this.errors = {};
            this.isSaving = true;

            if (!this.containerCode || /^\s*$/.test(this.containerCode)) {
                this.errors = {containerCode: 'Enter a manifest code!'};
                this.isSaving = false;
                return false;
            }

            const url = new URL(this.$store.state.baseUrl + 'scout-container/assign-parcel-to-container');
            url.searchParams.set('containerId', this.containerCode);

            const params = new URLSearchParams();
            params.append('PARCEL_GUID', this.deliveryItemId);

            this.$http.post(url.href, params).then((data) => {
                this.isSaving = false;

                if (data?.data?.code === 404) {
                    this.errors = {containerCode: data.data.errors};

                    this.$nextTick(() => {
                        this.$refs.scanContainerInput.focus();
                    });
                } else if (data?.data?.code !== 200) {
                    this.errors = {containerCode: 'Something went wrong while trying to assign the parcel to the container.'};

                    this.$nextTick(() => {
                        this.$refs.scanContainerInput.focus();
                    });
                } else {
                    /* Add the item to the list of recently added items. Keep the list limited to 3 entries. */
                    this.recentlyAddedItems.unshift({
                        parcelReference: this.parcelReference,
                        containerCode: this.containerCode
                    });

                    this.recentlyAddedItems.length = Math.min(this.recentlyAddedItems.length, 3);

                    this.parcelReference = null;
                    this.clearData();

                    /* Focus on the "Scan reference" input field after activating it. */
                    this.$nextTick(() => {
                        this.$refs.scanReferenceInput.focus();
                    });
                }
            });
        },
        openNewContainer() {
            if (!this.parcelNextTransportStepId) {
                this.errors = {createContainer: 'Unable to create a container. Missing required transport step information from the parcel.'};
            }

            this.isCreatingContainer = true;

            const url = new URL(this.$store.state.baseUrl + 'scout-container/create-container');
            url.searchParams.set('transportStepId', this.parcelNextTransportStepId);

            this.$http.get(url.href).then((data) => {
                this.isCreatingContainer = false;

                if (!data?.data?.id) {
                    this.errors = {createContainer: 'Something went wrong while trying to create a container.'};
                    return;
                }

                this.getScoutContainers();
            });
        },

        clearData() {
            this.containerCode = null;
            this.scanContainerInputActive = false;
            this.containers = [];
            this.hasScannedValidParcel = false;
        },
    },
    computed: {
        isLoading() {
            return this.isLoadingParcel || this.isLoadingContainers;
        },
    },
    watch: {
        parcelReference() {
            this.scanContainerInputActive = false;
            this.containerCode = null;
            this.containers = [];
        }
    }
}
</script>

<style scoped>
.added-to-manifest-message-0, .saving-message {
    font-size: 16px;
    margin-top: 30px;
    color: #1d2640;
}

.added-to-manifest-message-1 {
    font-size: 16px;
    color: #868686;
}

.added-to-manifest-message-2 {
    font-size: 16px;
    color: #bbb;
}

.scout-containers-container {
    max-height: 300px;
    overflow-y: auto;
}
</style>
