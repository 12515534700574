<template>
    <div>
        <HeaderBar/>

        <main class="main">
            <div class="scrollable-area">
                <div class="scrollable-content">
                    <div class="container">
                        <form action="" @submit.prevent="scanReference()">
                            <div class="form-group">
                                <label for="container-code" class="label-title">
                                    Scan to view container
                                </label>

                                <input
                                    type="text"
                                    placeholder="Scan container code"
                                    class="form-control lg"
                                    id="container-code"
                                    ref="scanReferenceInput"
                                    v-model="containerCode"
                                >
                            </div>
                        </form>

                        <div class="row">
                            <div class="col-12">
                                <InputFieldError attribute="containerCode" :errors="errors"/>
                            </div>
                        </div>

                        <div class="scan-manisfest-container">
                            <div class="row">
                                <template v-if="isLoading">
                                    Loading containers...
                                </template>

                                <template v-else-if="!containers?.length">
                                    There are no open containers.
                                </template>

                                <template v-else>
                                    <div class="col-sm-6 col-lg-3" v-for="(container, key) in containers" :key="key">
                                        <router-link
                                            class="text-decoration-none scan-manifest-card-link"
                                            :to="{
                                                name: 'ShippingScoutContainerDetail',
                                                params: {scoutContainerId: container.ID},
                                                query: {from: 'container-overview'}
                                            }"
                                        >
                                            <OverviewCardItem
                                                :label="container.NAME"
                                                :count="container.DELIVERY_ITEMS"
                                                :items="[{ label: 'Lane', value: container.LANE }]"
                                                ref="cardItem"
                                            />
                                        </router-link>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="section-footer">
                    <div class="container">
                        <div class="d-flex justify-content-between">
                            <router-link :to="{name: 'ShippingManifestsAndContainersDashboard'}" class="btn btn-secondary">
                                Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import InputFieldError from '@/components/_InputFieldError.vue';
import OverviewCardItem from '@/components/_OverviewCardItem.vue';

export default {
    name: 'ScoutContainerOverview',
    components: {
        'HeaderBar': HeaderBar,
        'InputFieldError': InputFieldError,
        'OverviewCardItem': OverviewCardItem,
    },
    data() {
        return {
            errors: {},
            isLoading: false,
            containerCode: null,
            containers: [],
        };
    },
    mounted() {
        this.$refs.scanReferenceInput.focus();
    },
    created() {
        this.getOpenContainers();
    },
    methods: {
        getOpenContainers() {
            this.isLoading = true;

            const url = new URL(this.$store.state.baseUrl + 'scout-container-group/get-all-containers');
            url.searchParams.set('state', 'Open');

            this.$http.get(url.href).then((data) => {
                this.isLoading = false;
                this.containers = data?.data ?? [];
            });
        },
        scanReference() {
            this.$router.push({
                name: 'ShippingScoutContainerDetail',
                params: {scoutContainerId: this.containerCode}
            });
        }
    },
}
</script>

<style scoped>

</style>
