<template>
    <div>
        <HeaderBar/>

        <main class="main">
            <div class="row col-12">
                <SettingsMenu activeCategory="PackageType" activeItem="Create"/>

                <div class="col-10">
                    <div class="mt-4" v-if="isLoading">
                        Loading package type...
                    </div>

                    <div class="mt-4" v-else-if="!packageType">
                        Package type not found.
                    </div>

                    <div class="mt-4" v-else-if="isSaving">
                        Saving package type...
                    </div>

                    <template v-else>
                        <div class="row">
                            <div class="col-md-6 my-3">
                                <label for="label">
                                    Label
                                </label>

                                <input
                                    type="text"
                                    id="label"
                                    class="form-control"
                                    v-model="packageType.LABEL"
                                >

                                <InputFieldError attribute="LABEL" :errors="errors"/>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-3 my-3">
                                <label for="default-metric-length">
                                    Default length (CM)
                                </label>

                                <input
                                    type="number"
                                    step="0.01"
                                    id="default-metric-length"
                                    class="form-control"
                                    v-model="packageType.DEFAULT_METRIC_LENGTH"
                                >

                                <InputFieldError attribute="DEFAULT_METRIC_LENGTH" :errors="errors"/>
                            </div>

                            <div class="col-md-3 my-3">
                                <label for="default-metric-width">
                                    Default width (CM)
                                </label>

                                <input
                                    type="number"
                                    step="0.01"
                                    id="default-metric-width"
                                    class="form-control"
                                    v-model="packageType.DEFAULT_METRIC_WIDTH"
                                >

                                <InputFieldError attribute="DEFAULT_METRIC_WIDTH" :errors="errors"/>
                            </div>

                            <div class="col-md-3 my-3">
                                <label for="default-metric-height">
                                    Default height (CM)
                                </label>

                                <input
                                    type="number"
                                    step="0.01"
                                    id="default-metric-height"
                                    class="form-control"
                                    v-model="packageType.DEFAULT_METRIC_HEIGHT"
                                >

                                <InputFieldError attribute="DEFAULT_METRIC_HEIGHT" :errors="errors"/>
                            </div>

                            <div class="col-md-3 my-3">
                                <label for="default-metric-weight">
                                    Default weight (KG)
                                </label>

                                <input
                                    type="number"
                                    step="0.01"
                                    id="default-metric-weight"
                                    class="form-control"
                                    v-model="packageType.DEFAULT_METRIC_WEIGHT"
                                >

                                <InputFieldError attribute="DEFAULT_METRIC_WEIGHT" :errors="errors"/>
                            </div>
                        </div>

                        <InputFieldError attribute="general" :errors="errors"/>
                    </template>

                    <button
                        class="btn md btn-primary mt-3"
                        @click="save()"
                        v-if="packageType && !isLoading && !isSaving"
                    >
                        Save
                    </button>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import InputFieldError from '@/components/_InputFieldError.vue';
import SettingsMenu from '@/components/_SettingsMainMenu.vue';

export default {
    name: 'PackageTypeUpdate',
    components: {
        'HeaderBar': HeaderBar,
        'InputFieldError': InputFieldError,
        'SettingsMenu': SettingsMenu,
    },
    data() {
        return {
            errors: {},
            isLoading: false,
            isSaving: false,
            packageType: {
                LABEL: null,
                DEFAULT_METRIC_LENGTH: null,
                DEFAULT_METRIC_WIDTH: null,
                DEFAULT_METRIC_HEIGHT: null,
                DEFAULT_METRIC_WEIGHT: null,
            },
        };
    },
    created() {
        if (this.$route.query.id) {
            this.getPackageType();
        }
    },
    methods: {
        getPackageType() {
            this.isLoading = true;

            const url = new URL(this.$store.state.baseUrl + 'package-types/find-by-id');
            url.searchParams.set('id', this.$route.query.id);

            this.$http.get(url.href).then((data) => {
                this.isLoading = false;
                this.packageType = data?.data ?? null;
            });
        },
        save() {
            this.errors = {};
            this.isSaving = true;

            const url = new URL(this.$store.state.baseUrl + 'package-types/save');

            if (this.$route.query.id) {
                url.searchParams.set('id', this.$route.query.id);
            }

            const params = new URLSearchParams();

            for (const [attribute, value] of Object.entries(this.packageType)) {
                if (value !== null) {
                    params.append(`PackageType[${attribute}]`, value);
                }
            }

            this.$http.post(url.href, params).then((data) => {
                this.isSaving = false;

                if (data?.data?.code === 200) {
                    this.$store.commit('showSuccessMessage', `Package type ${this.packageType.LABEL} has been updated.`);

                    this.$router.push({
                        name: 'PackageTypeOverview'
                    });
                } else {
                    this.errors = data?.data?.errors ?? {general: 'Something went wrong while trying to save the package type.'};
                }
            });
        },
    }
}
</script>
