<template>
    <div>
        <HeaderBar/>

        <main class="main">
            <form action="">
                <div class="scrollable-area">
                    <div class="scrollable-content">
                        <div class="container">
                            <div class="table-responsive">
                                <template v-if="isLoading">
                                    Loading container...
                                </template>

                                <template v-else-if="!container || Object.keys(container).length === 0">
                                    No container found with code {{ $route.params.scoutContainerId }}
                                </template>

                                <template v-else>
                                    <table class="table-first-level">
                                        <thead>
                                            <tr>
                                                <th>Container</th>
                                                <th>Parcels</th>
                                                <th>Lane</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td colspan="3">
                                                    <table class="table-second-level">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    {{ container.containerNumber }}
                                                                </th>

                                                                <th>
                                                                    {{ container.deliveryItemIds?.length }}
                                                                </th>

                                                                <th>
                                                                    {{ lane }}
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td colspan="3">
                                                                    <template v-if="container.state === 'Open'">
                                                                        <p v-if="!container.deliveryItemIds?.length">
                                                                            There are no parcels assigned to this container.
                                                                            This means the container will be deleted, instead of closed.
                                                                        </p>

                                                                        <button
                                                                            type="button"
                                                                            class="btn btn-primary p-3"
                                                                            :disabled="isClosingContainer"
                                                                            @click.prevent="handleCloseContainer()"
                                                                        >
                                                                            Close container
                                                                        </button>

                                                                        <template v-if="Object.keys(errors).length">
                                                                            <InputFieldError :errors="errors"/>
                                                                        </template>
                                                                    </template>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <router-link :to="backRoute" class="btn btn-secondary">
                                    Back
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </main>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import InputFieldError from '@/components/_InputFieldError.vue';

export default {
    name: 'ScoutContainerDetail',
    components: {
        'HeaderBar': HeaderBar,
        'InputFieldError': InputFieldError,
    },
    data() {
        return {
            errors: {},
            isLoading: false,
            isClosingContainer: false,
            container: null,
            lane: null,
        };
    },
    created() {
        this.getScoutContainer();
    },
    methods: {
        getScoutContainer() {
            this.isLoading = true;

            const url = new URL(this.$store.state.baseUrl + 'scout-container/find-container');
            url.searchParams.set('containerReference', this.$route.params.scoutContainerId);

            this.$http.get(url.href).then((data) => {
                this.isLoading = false;

                if (data?.data?.code === 404) {
                    this.errors = `No container found with code ${this.$route.params.scoutContainerId}`;
                } else if (data?.data?.code !== 200) {
                    this.errors = `Something went wrong while trying to retrieve the container with code ${this.$route.params.scoutContainerId}`;
                } else {
                    this.container = data?.data?.data?.container ?? null;
                    this.lane = data?.data?.data?.lane ?? null;
                }
            });
        },
        handleCloseContainer() {
            if (this.container.deliveryItemIds?.length) {
                this.closeScoutContainer();
            } else {
                this.deleteScoutContainer();
            }
        },
        closeScoutContainer() {
            this.isClosingContainer = true;

            const url = new URL(this.$store.state.baseUrl + 'scout-container/close-container');
            url.searchParams.set('containerId', this.container?.id);

            this.$http.get(url.href).then((data) => {
                this.isClosingContainer = false;

                if (data?.data?.code === 200) {
                    this.$store.commit('showSuccessMessage', `Container ${this.container.containerNumber} has been closed.`);

                    this.$router.push(this.backRoute);
                } else {
                    this.errors = 'Something went wrong while trying to close the container.';
                }
            });
        },
        deleteScoutContainer() {
            this.isClosingContainer = true;

            const url = new URL(this.$store.state.baseUrl + 'scout-container/delete-container');
            url.searchParams.set('containerId', this.container?.id);

            this.$http.get(url.href).then((data) => {
                this.isClosingContainer = false;

                if (data?.data?.code === 200) {
                    this.$store.commit('showSuccessMessage', `Container ${this.container.containerNumber} has been closed.`);

                    this.$router.push(this.backRoute);
                } else {
                    this.errors = 'Something went wrong while trying to close the container.';
                }
            });
        }
    },
    computed: {
        backRoute() {
            if (this.$route.query.from === 'container-group') {
                if (this.$route.query.containerGroupId) {
                    return {
                        name: 'ShippingScoutContainerGroupDetail',
                        params: {scoutContainerGroupId: this.$route.query.containerGroupId}
                    };
                }

                return {name: 'ShippingScoutContainerGroupOverview'};
            }

            return {name: 'ShippingScoutContainerOverview'};
        }
    }
}
</script>

<style scoped>

</style>
