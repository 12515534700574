<template>
    <div>
        <HeaderBar title="Module select"></HeaderBar>

        <main class="main">
            <section class="section-dashboard">
                <div class="container">
                    <div class="d-flex flex-wrap justify-content-between mb-5">
                        <h2 class="section-title">
                            Shipping outbound
                        </h2>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <InputFieldError attribute="global" :errors="errors"/>
                        </div>
                    </div>

                    <template v-if="isLoadingModuleAccess">
                        Loading submodules...
                    </template>

                    <template v-else-if="!canShipManifests && !canShipShipments && !canShipContainerGroups">
                        No submodules available.
                    </template>

                    <template v-else>
                        <div class="card-list sm">
                            <div class="card-item-wrapper">
                                <div class="card-item lg" v-if="canShipManifests">
                                    <router-link
                                        :to="{name: 'ShipParcelManifest', query: {type: 'sorted', carrierLocationId: carrierLocationId}}"
                                        class="card-item--link mb-3"
                                        :class="!carrierLocationId ? 'inactive' : ''"
                                    >
                                        <div class="card-item-img">
                                            <img :src="require('@/assets/images/colored-icons/receive.svg')"
                                                 class="img-fluid"
                                                 alt="">
                                        </div>

                                        <span class="d-block pt-4">
                                            SHIP PARCEL MANIFESTS
                                        </span>
                                    </router-link>

                                    <template v-if="!isLoadingCarrierLocations && carrierLocationOptions.length === 0">
                                        No destinations found
                                    </template>

                                    <model-list-select
                                        :list="carrierLocationOptions"
                                        option-value="ID"
                                        option-text="LABEL"
                                        v-model="carrierLocationId"
                                        placeholder="Select destination"
                                        required
                                        v-else
                                    >
                                    </model-list-select>
                                </div>
                            </div>

                            <MenuCardItem
                                :route="{name: 'ParcelShipmentOverview'}"
                                :icon-path="require('@/assets/images/colored-icons/receive.svg')"
                                label="PARCEL SHIPMENT OVERVIEW"
                                v-if="canShipShipments"
                            />

                            <MenuCardItem
                                :route="{name: 'ShippingScoutContainerGroupOverview'}"
                                :icon-path="require('@/assets/images/colored-icons/receive.svg')"
                                label="SHIP CONTAINERS"
                                v-if="canShipContainerGroups"
                            />
                        </div>
                    </template>
                </div>
            </section>

            <div class="scrollable-area">
                <div class="section-footer">
                    <div class="container">
                        <div class="d-flex justify-content-between">
                            <router-link :to="{name: 'ShippingModuleSelect'}" class="btn btn-secondary">
                                Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import InputFieldError from '@/components/_InputFieldError.vue';
import MenuCardItem from '@/components/_MenuCardItem.vue';
import {ModelListSelect} from 'vue-search-select';

export default {
    name: 'ShippingOutboundDashboardPage',
    components: {
        'HeaderBar': HeaderBar,
        'InputFieldError': InputFieldError,
        'MenuCardItem': MenuCardItem,
        'ModelListSelect': ModelListSelect,
    },
    data() {
        return {
            errors: {},
            isLoadingModuleAccess: false,
            isLoadingCarrierLocations: false,
            canShipManifests: false,
            canShipShipments: false,
            canShipContainerGroups: false,
            carrierLocationOptions: [],
            carrierLocationId: null,
        };
    },
    mounted() {
        this.isLoadingModuleAccess = true;

        this.$root.$on('loadedRoleModuleAccess', () => {
            this.isLoadingModuleAccess = false;
            this.moduleAccess = this.$store.state.roleAccess?.['role-module-access']?.[this.$store.state.users.currentUser.ROLE_ID];

            const shippingModuleAccess = this.moduleAccess?.['shipping']?.['shipping'] ?? undefined;

            this.canShipManifests = shippingModuleAccess?.['ship-manifests']?.ACCESS;
            this.canShipShipments = shippingModuleAccess?.['ship-shipments']?.ACCESS;
            this.canShipContainerGroups = shippingModuleAccess?.['ship-scout-container-groups']?.ACCESS;
        });
    },
    created() {
        this.getCarrierLocations();
    },
    methods: {
        getCarrierLocations() {
            this.isLoadingCarrierLocations = true;

            const url = new URL(this.$store.state.baseUrl + 'locations/get-all-carrier-and-second-tier-warehouses');
            url.searchParams.set('shorten', 'true');

            this.$http.get(url.href).then((data) => {
                this.isLoadingCarrierLocations = false;
                this.carrierLocationOptions = data?.data ?? [];
            });
        }
    },
}
</script>

<style scoped>
.card-item--link.inactive {
    filter: grayscale(60%);
    opacity: 0.3;
    pointer-events: none;
}

.card-item--link {
    color: #1d2640;
}

.card-item--link:hover {
    text-decoration: none;
    color: #4a90e2;
}
</style>
