<template>
    <div>
        <HeaderBar/>

        <main class="main">
            <div class="scrollable-area">
                <div class="scrollable-content">
                    <div class="container">
                        <div class="scan-manisfest-container">
                            <div class="row">
                                <template v-if="isLoading">
                                    Loading container groups...
                                </template>

                                <template v-else-if="!containerGroups?.length">
                                    There are no open container groups.
                                </template>

                                <template v-else>
                                    <div class="col-sm-6 col-lg-3" v-for="(containerGroup, key) in containerGroups" :key="key">
                                        <router-link
                                            class="text-decoration-none scan-manifest-card-link"
                                            :to="{name: 'ShippingScoutContainerGroupDetail', params: {scoutContainerGroupId: containerGroup.id}}"
                                        >
                                            <OverviewCardItem
                                                :label="containerGroup.name"
                                                :count="containerGroup.containers?.length ?? 0"
                                                :items="[{ label: 'Container group number', value: containerGroup.containerGroupNumber ?? '' }]"
                                                ref="cardItem"
                                            />
                                        </router-link>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="section-footer">
                    <div class="container">
                        <div class="d-flex justify-content-between">
                            <router-link :to="{name: 'ShippingOutboundDashboard'}" class="btn btn-secondary">
                                Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import OverviewCardItem from '@/components/_OverviewCardItem.vue';

export default {
    name: 'ScoutContainerGroupOverview',
    components: {
        'HeaderBar': HeaderBar,
        'OverviewCardItem': OverviewCardItem,
    },
    data() {
        return {
            isLoading: false,
            containerGroups: [],
        };
    },
    created() {
        this.getOpenContainerGroups();
    },
    methods: {
        getOpenContainerGroups() {
            this.isLoading = true;

            const url = new URL(this.$store.state.baseUrl + 'scout-container-group/get-all');

            this.$http.get(url.href).then((data) => {
                this.isLoading = false;
                this.containerGroups = data?.data ?? [];
            });
        },
    },
}
</script>

<style scoped>

</style>
