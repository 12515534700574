<template>
    <LeftMenuBase :menu="menu" />
</template>


<script>
import LeftMenuBase from './_LeftMenuBase';

export default {
    components: {
        'LeftMenuBase': LeftMenuBase
    },
    props: ['activeCategory', 'activeItem', 'roleId'],
    data() {
        return {
            menu: {
                categories: {
                    Role: {
                        name: 'Role',
                        routeName: 'RoleOverview',
                        items: {
                            Overview: {routeName: 'RoleOverview'},
                            General: {routeName: 'RoleUpdate', routeQuery: {roleId: this.roleId}},
                        }
                    },
                    Access: {
                        name: 'Role access',
                        routeName: 'RoleUpdate',
                        routeQuery: {roleId: this.roleId},
                        items: {
                            Locations: {routeName: 'RoleLocationAccess', routeQuery: {roleId: this.roleId}},
                            LocationAccounts: {
                                name: 'Location accounts',
                                routeName: 'RoleLocationAccountAccess',
                                routeQuery: {roleId: this.roleId}
                            },
                            Modules: {
                                routeName: 'RoleModuleAccess',
                                routeQuery: {roleId: this.roleId}
                            }
                        }
                    }
                }
            }
        };
    },
    created() {
        this.menu['activeCategory'] = this.activeCategory;
        this.menu['activeItem'] = this.activeItem;
    }
}
</script>
