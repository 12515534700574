<template>
    <div class="col-2 block-left">
        <ul v-for="(category, categoryKey) in menu.categories" :key="categoryKey">
            <li>
                <strong>
                    <router-link :to="getRouteObject(category)">
                        {{ category.name }}
                    </router-link>
                </strong>
            </li>

            <li>
                <ul>
                    <li v-for="(item, itemKey) in category.items" :key="itemKey">
                        <router-link :to="getRouteObject(item)"
                                     :class="{ active: categoryKey === menu.activeCategory && itemKey === menu.activeItem }"
                        >
                            {{ typeof item.name !== 'undefined' ? item.name : itemKey }}
                        </router-link>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>


<script>
export default {
    /*
        Menu structure expected below:
    */
    /*
        menu: {
            activeCategory: 'Role',
            activeItem: 'General',
            categories: {
                Role: {
                    name: 'Role',
                    routeName: 'RoleOverview',
                    items: {
                        Overview: {routeName: 'RoleOverview'},
                        General: {routeName: 'RoleUpdate', routeQuery: {roleId: this.roleId}},
                    }
                },
                User: {
                    name: 'User',
                    routeName: 'UserOverview',
                    items: {
                        Overview: {routeName: 'UserOverview'},
                        Create: {routeName: 'UserUpdate', routeQuery: {userId: 123}},
                    }
                },
                Access: {
                    name: 'Role access',
                    routeName: 'RoleUpdate',
                    routeQuery: {roleId: this.roleId},
                    items: {
                        ...
                    }
                },
                Workstation: {
                    name: 'Workstations',
                    routeName: 'WorkstationOverview',
                    routeParams: {locationId: this.$route.query.locationId},
                    items: {
                        Overview: {
                            routeName: 'WorkstationOverview',
                            routeParams: {locationId: this.$route.query.locationId}
                        },
                    }
                },
            }
        }
    */
    props: [
        'menu'
    ],
    methods: {
        getRouteObject(item) {
            return {
                name: item.routeName,
                query: item.routeQuery,
                params: item.routeParams
            };
        }
    }
}
</script>

<style scoped>
a {
    text-decoration: none !important;
    color: black;
}

ul li {
    font-size: 21px;
    color: #0C1334;
    cursor: pointer;
}

ul {
    margin-bottom: 20px;
}

.active {
    color: blue !important;
}
</style>
