<template>
    <header class="header">
        <div class="container">
            <nav class="navbar justify-space-between">
                <router-link :to="{name: 'ModuleSelect'}" class="navbar-brand">
                    <img :src="require('@/assets/images/logo-icon.svg')" class="img-fluid" alt="">

                    <template v-if="operatorLocation && operatorLocation.account">
                        <strong>
                            {{ operatorLocation.account }} -
                        </strong>
                    </template>

                    <template v-if="operatorLocation && operatorLocation.location">
                        {{ operatorLocation.location }}
                    </template>

                    <template v-if="operatorWorkstation">
                        - {{ operatorWorkstation }}
                    </template>

                </router-link>

                <div class="navbar-nav-right d-flex">
                    <router-link :to="{name: 'UserOverview'}" class="navbar-nav-right-link" v-if="hasSettingsAccess">
                        <i class="icon-settings"></i>
                        Settings
                    </router-link>

                    <router-link :to="{name: 'EditProfile'}" class="navbar-nav-right-link" v-if="currentUser">
                        <i class="icon-account"></i>
                        Account
                    </router-link>

                    <a href="" class="navbar-nav-right-link" v-on:click.prevent="logout()" v-if="currentUser">
                        <i><img :src="require('@/assets/images/icons/logout.svg')" alt=""></i>
                        Logout
                    </a>
                </div>
            </nav>
        </div>

        <div class="alert alert--success d-flex align-items-center" role="alert" v-if="$store.state.message">
            <span class="icon icon-Success"></span>

            {{ $store.state.message }}
        </div>
    </header>
</template>

<script>
    export default {
        data() {
            return {
                initLoaded: false,
                currentUser: null,
                hasSettingsAccess: false,
            };
        },
        props: ['title'],
        mounted() {
            if (this.$store.state.users.currentUser) {
                let currentUser = this.$store.state.users.currentUser;

                this.$intercom.boot({
                    name: currentUser.FIRST_NAME + ' ' + currentUser.LAST_NAME,
                    email: currentUser.EMAIL,
                    created_at: currentUser.CREATION
                });
            }
        },
        created() {
            this.$store.dispatch('users/getCurrentUser').then((data) => {
                this.initLoaded = true;
                this.currentUser = data;

                if (this.currentUser) {
                    this.$store.dispatch('roleAccess/getByType', {roleId: this.currentUser.ROLE_ID, type: 'role-module-access'}).then(() => {
                        this.isLoadingSubmodules = false;
                        this.moduleAccess = this.$store.state.roleAccess?.['role-module-access']?.[this.currentUser.ROLE_ID]?.['general']?.['settings'];
                        this.hasSettingsAccess = typeof this.moduleAccess?.['settings'] !== 'undefined' && this.moduleAccess['settings'].ACCESS;

                        this.$root.$emit('loadedRoleModuleAccess');
                    });

                    this.$store.dispatch('userSettings/getOperatorLocation');
                    this.$store.dispatch('userSettings/getOperatorWorkstation');
                }
            });
        },
        methods: {
            logout: function () {
                this.initLoaded = false;
                this.$intercom.shutdown();
                this.$store.commit('clear');
                this.$store.dispatch('users/logout').then(() => {
                    this.$router.push({
                        name: 'Login'
                    });
                });
            }
        },
        computed: {
            operatorLocation() {
                return this.$store.state.userSettings.operatorLocation;
            },
            operatorWorkstation() {
                return this.$store.state.userSettings.operatorWorkstation;
            },
        }
    }
</script>

<style>

</style>
