<template>
    <div>
        <HeaderBar title="Module select"></HeaderBar>

        <main class="main">
            <section class="section-dashboard">
                <div class="container">
                    <div class="d-flex flex-wrap justify-content-between mb-5">
                        <h2 class="section-title">
                            Shipping manifests and containers
                        </h2>
                    </div>

                    <template v-if="isLoadingModuleAccess">
                        Loading submodules...
                    </template>

                    <template v-else-if="!canViewManifests && !canHandleScoutContainers">
                        No submodules available.
                    </template>

                    <template v-else>
                        <div class="card-list sm">
                            <MenuCardItem
                                :route="{name: 'ShippingManifestOverview'}"
                                :icon-path="require('@/assets/images/colored-icons/receive.svg')"
                                label="VIEW MANIFESTS"
                                v-if="canViewManifests"
                            />

                            <MenuCardItem
                                :route="{name: 'ShippingScoutContainerOverview'}"
                                :icon-path="require('@/assets/images/colored-icons/receive.svg')"
                                label="HANDLE CONTAINERS"
                                v-if="canHandleScoutContainers"
                            />
                        </div>
                    </template>
                </div>
            </section>

            <div class="scrollable-area">
                <div class="section-footer">
                    <div class="container">
                        <div class="d-flex justify-content-between">
                            <router-link :to="{name: 'ShippingModuleSelect'}" class="btn btn-secondary">
                                Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import MenuCardItem from '@/components/_MenuCardItem.vue';

export default {
    name: 'ShippingManifestsAndContainersDashboardPage',
    components: {
        'HeaderBar': HeaderBar,
        'MenuCardItem': MenuCardItem,
    },
    data() {
        return {
            isLoadingModuleAccess: false,
            canViewManifests: false,
            canHandleScoutContainers: false,
        };
    },
    mounted() {
        this.isLoadingModuleAccess = true;

        this.$root.$on('loadedRoleModuleAccess', () => {
            this.isLoadingModuleAccess = false;
            this.moduleAccess = this.$store.state.roleAccess?.['role-module-access']?.[this.$store.state.users.currentUser.ROLE_ID];

            const shippingModuleAccess = this.moduleAccess?.['shipping']?.['shipping'] ?? undefined;

            this.canViewManifests = shippingModuleAccess?.['view-manifests']?.ACCESS;
            this.canHandleScoutContainers = shippingModuleAccess?.['handle-scout-containers']?.ACCESS;
        });
    },
}
</script>

<style scoped>

</style>
